import instance from "src/axios-instance";
import { IResetPasswordAuth } from "src/interfaces/reset-password.interface";
import { UserRegistration } from "src/interfaces/user-registration.interface";

export function forgotPassword(email: string): any {
  return instance.post("auth/forgot-password", email);
}

export function resetPassword(data: IResetPasswordAuth): any {
  return instance.put(`auth/reset-password`, data);
}

export function activateAccount(activationKey: string): any {
  return instance.put(`auth/activate`, activationKey);
}

export function resendRegistrationEmail(
  email: string,
  sendActivation?: boolean
): any {
  const sendActivationParam = sendActivation ? `/${sendActivation}` : "";
  return instance.get(`auth/resend-activation/${email}` + sendActivationParam);
}

export function registration(registerUser: UserRegistration): any {
  return instance.post(`auth/register`, registerUser);
}

export function logoutRequest(): any {
  return instance.get(`auth/logout`);
}
export function logoutCacheRequest(): any {
  return instance.get(`auth/logoutCache`);
}